import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import Img from "gatsby-image"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <h1>Blogs</h1>
    {data.allNodeArticle.edges.map(edge => (
      <>
        <h3><Link to={ edge.node.id }>{ edge.node.title }</Link></h3>
        <small><em>{ Date(edge.node.created) }</em></small>

        {edge.node.relationships.field_image !== null &&
          <div style={{ maxWidth: `300px`, marginBottom: `1.45rem`, width: `100%` }}>
            <Img fluid={ edge.node.relationships.field_image.localFile.childImageSharp.fluid } />
          </div>
        }

        <div dangerouslySetInnerHTML={{ __html: edge.node.body.value.split(' ').splice(0, 50).join(' ') + '...' }}></div>
      </>
    ))}
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allNodeArticle {
      edges {
        node {
          id
          title
          body {
            value
          }
          created
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
